import React, { useEffect, useState } from 'react';
import { Link } from "gatsby";
import { Emoji } from '../../components/Utils';
import { getAddressMP, addressShort } from "../../components/Mpurse";
// import SeoSpace from '../../components/SeoSpace';
import { ITEMS_SPACE } from "../../components/InitItemsSpace";
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = () => {
  const [ usersAddress, setusersAddress ] = useState("Connect Wallet");
  const [ popupWindow, setPopuppWindow ] = useState(<></>);
  const ASKMONA_LINK = "https://web3.askmona.org/154";

  // useEffect(() => {
  //   setTimeout(() => {
  //     const element = document.getElementById("canvas");
  //     if(element != null) { element.remove() }
  //   }, 100);
  // }, []);

  reqAddressFromMpurse();
  async function reqAddressFromMpurse() {
    let addr = await getAddressMP();
    if(ITEMS_SPACE.env === "l") addr = ITEMS_SPACE.envAddress;
    if(addr === "") {
      setusersAddress("Connect Wallet");
    } else {
      // const shortAddr = addressShort(addr);
      const shortAddr = addr;
      setusersAddress(shortAddr);
    }
  }

  return(
    <>
      {/* <SeoSpace /> */}
      { popupWindow }
      <div id="menuField" className="text-center text-gray-900">
        <div className="w-full h-fit px-4 sm:px-8 py-12">
          <div>
            <div className="h-screen flex justify-center items-center">
              <div>
                <p className="font-light italic text-4xl sm:text-5xl">My Page</p>
                {/* <p className="m-2 text-xl">Create your own space!</p> */}
                <button onClick={ reqAddressFromMpurse } className="p-6 bg-white border-solid border-2 border-gray-700 rounded-full mt-4 transition duration-[250ms] ease-out hover:opacity-30 hover:invert">
                  <p className="text-xl sm:text-xl">{ usersAddress }</p>
                </button>
              </div>
            </div>


            <div>
              <div className="w-1/3">
                <StaticImage src="../../images/Space001.png" alt="Space001" />
              </div>
              <div className="w-1/3">
                <StaticImage src="../../images/Space001.png" alt="Space002" />
              </div>
            </div>
            
            
            <div className="py-2 sm:grid sm:grid-cols-2 sm:gap-8">
              <div>
                <Link to="edit">
                  <div className="py-48 bg-white border-solid border-2 border-gray-900 rounded-tl-[48px] rounded-br-[48px] mt-8 transition duration-[250ms] ease-out hover:opacity-30 hover:invert overflow-hidden">
                    <p className="text-2xl sm:text-3xl">Create or Edit Space
                      <span className="text-5xl">  { Emoji.musiumEmoji }</span>
                    </p>
                  </div>
                </Link>
              </div>
              <div>
                <Link to="visit">
                  <div className="py-48 bg-white border-solid border-2 border-gray-900 rounded-tl-[48px] rounded-br-[48px] mt-8 transition duration-[250ms] ease-out hover:opacity-30 hover:invert overflow-hidden">
                    <p className="text-2xl sm:text-3xl">Visit Space
                      <span className="text-5xl">  { Emoji.musiumEmoji }</span>
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-32 px-12 py-16 border-solid border-2 border-gray-900 rounded-tl-[48px] rounded-br-[48px] text-sm text-left">
              <p className="text-2xl ">About</p>
              <div className="p-2">
                <p className="">You can display Monacard.</p>
                <p className="">Please display Monacard with respect.</p>
                <p className="">APNGs are displayed as still images. GIFs work.</p>
                <p className="">You can register up to206 cards.</p>
                <p className="">One type of gallery.</p>
                <p className="">You can register one gallery per address.</p>
              </div>
              <p className="mt-4 text-xl ">ここについて</p>
              <div className="p-2">
                <p className="">モナカードを飾ることが出来ます。</p>
                <p className="">作品をリスペクトして飾りましょう。</p>
                <p className="">APNGは静止画での表示となります。GIFは動きます。</p>
                <p className="">ギャラリーのタイプは1種類です。</p>
                <p className="">登録出来るカードは20枚です。</p>
                <p className="">1つのアドレスでギャラリーを1つ登録出来ます。</p>
              </div>
              <div className="mt-8 px-12 py-16 border-solid border-2 border-gray-900 rounded-tl-[48px] rounded-br-[48px] text-sm text-left">
                <p className="">Please send any problems to Twitter and AskMona3.0.</p>
                <p className="">AskMona3.0 is <a className="underline" href={ ASKMONA_LINK }>HERE.</a></p>
                <p className="mt-4">不具合等はTwitter, AskMona3.0へお願いします。</p>
                <p className="">AskMona3.0は<a className="underline" href={ ASKMONA_LINK }>こちら。</a></p>
              </div>
            </div>
            <div className="flex justify-center content-center py-20">
              <Link to="/">
                <div className="px-16 py-20 bg-white border-solid border-2 border-gray-900 rounded-tl-[48px] rounded-br-[48px] mt-8 transition duration-[250ms] ease-out hover:opacity-30 hover:invert overflow-hidden">
                  <p className="text-2xl sm:text-3xl">monanosu HOME
                    <span className="text-5xl">  { Emoji.fujisannEmoji }</span>
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IndexPage;